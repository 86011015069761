.ngram-search {
    padding: 10px;
    width: 100%;
}
.ngram-search h2 {
    margin-bottom: 10px;
}

.ngram-search h4 {
    margin-top: 0;
}

.ngram-search_form-group {
    margin-bottom: 10px;
}

.ngram-search_form-group label {
}
.ngram-search_form-group input {
    border: 1px solid #e9ecef;
    border-radius: 8px;
    font-size: 14px;
    padding: 10px;
}
.ngram-search_single-input input {
    width: 100%;
}
.ngram-search_mult-input input {
    width: 20%;
    margin-left: 10px;
}
.ngram-search_mult-input label {
    margin-right: 10px;
}
.ngram-search_form h2 {
    margin-bottom: 10px;
}
.ngram-search_form-group button {
    width: 100%;
}
.ngram-search_result-group h5 strong {
    margin-right: 10px;
}
.ngram-search_sentences {
    overflow: hidden;
    display: table;
    padding: 10px;
    line-height: 150%;
}
.ngram-search_sentences a {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
}
.ngram-search_sentence_single {
    display: table-row;
}
.ngram-search_sentence_single>strong, .ngram-search_sentence_single>span {
    display: table-cell;
}
.ngram-search_sentence_single>strong {
    white-space: nowrap;
    padding-right: 5px;
}
.ngram-search_result-highlight {
    background: #FFDC00;
    border: 1px solid transparent;
    border-radius: 3px;
}

.ngram-search_result-highlight.ngram-search_result-emotional {
    background: #2ECC40;
}
