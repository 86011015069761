.verbatim-sentence {
    font-size: 20px;
    line-height: 1.4;
}

.verbatim-popup {
    font-weight: inherit;
    font-size: 15px;
}

.verbatim-popup span.verbatim-popup-item {
    display: block;
    margin: 10px 5px;
}

.response_component {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 20px 10px;
    border-bottom: 1px solid #e0e0d9;
}

.response_component__header {
    width: 100%;
    display: flex;
    padding: 5px 10px;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
}

.response_component__header__header {
    display: flex;
}

.key {
    margin-right: 5px;
    font-weight: 400;
}

.tag {
    border-radius: 10px;
    padding: 7px;
    color: rgba(0, 0, 0, 0.6);
    margin: 5px 10px 5px 0;
}

.response_component__header__hb_index {
    display: flex;
}

.response_component__value {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 10px;
    font-size: 20px;
    line-height: 1.4;
}

.response_component__words {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
    align-items: baseline;
}

.response_component__primaries {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 10px;
    align-items: baseline;
    white-space: pre-wrap;
}

.response_component__header__score {
    display: flex;
}

.response-hl {
    padding: 0 5px;
    border-radius: 5px;
}

.response-sentence {
    white-space: pre-wrap;
}

.response-sentence-negator {
    border: 1px dashed red;
    background: #eee;
}

.response-sentence-negator-tail {
    border: 0;
    background: #eee;
}

.response-sentence-emoword {
    padding: 0 0 3px 0;
    border-radius: 0;
    position: relative;
    background-position: 120%, 0 130%;
    background-repeat: no-repeat;
    background-size: 100% 9px;
    cursor: default;
}

.response-sentence-emoword-popup {
    position: absolute;
    width: 200px;
    margin-left: -100px;
    padding: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    z-index: 9999990;
}
.response-sentence-emoword-popup>span {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
}
.response-sentence-emoword-popup>span:last-child {
    margin-bottom: 0;
}
.emoword-popup-tag {
    padding: 0 3px;
    border-radius: 2px;
}
.response-sentence-emoword-popup .emoword-popup-tag {
    margin-right: 2px;
}

.response-sentence-original {
    color: #55C;
    display: block;
    border-bottom: 1px dotted #ddd;
    padding: 0 0 10px;
    margin-bottom: 10px;
}

.response_component .divider {
    text-indent: -9999em;
}
