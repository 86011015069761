.split-chart {
    display: table;
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
}

.split-chart .y-axis path,
.split-chart .y-axis line {
    stroke: none;
}

.split-chart-tooltip {
    font-size: 14px;
    width: 200px;
    text-align: left;
    padding: 5px 0;
}

.split-chart-tooltip>span {
    display: block;
    margin-bottom: 8px;
}

.split-chart-tooltip>span:last-child {
    margin-bottom: 0;
}

.split-chart-tooltip-tag {
    display: inline;
    border-radius: 4px;
    color: white;
    padding: 2px 5px;
    margin-left: 5px;
}

.chart-bar-pieces .bar {
    height: 45px;
    top: calc(50% - 23px);
    overflow: hidden;
    white-space: nowrap;
    color: white;
    display: inline-block;
    align-items: center;
    transition: width 0.3s, left 0.3s, filter 0.3s;
}

.chart-bar{
    display: table-row;
    height: 40px;
    width: 100%;
}
.chart-bar-title{
    line-height: 0.9;
    text-align: right;
    margin-right: 10px;
    display: table-cell;
    min-height: 45px;
    padding-right: 5px;
    vertical-align: middle;
}
.chart-bar-pieces{
    display: table-cell;
    width: 100%;
    height: 45px;
    text-align: left;
}
.chart-bar-pieces .bar:hover {
    filter: brightness(1.2);
}
.chart-bar-pieces .bar-label {
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    padding-left: 5px;
    margin-top: 15px;
}
.split-chart-index {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.split-chart-index div > div {
    height: 15px;
    width: 15px;
    margin-right: 5px;
}

.split-chart-index > div {
    display: flex;
    margin-right: 20px;
    margin-top: 10px;
}

.hx-index-chart {
    position: relative;
}
.hx-index-chart-vertical-line-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.hx-index-chart-vertical-line {
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 1px;
    margin: auto;
}

.hx-index-chart-bar {
    position: relative;
}
.hx-index-chart-bar div {
    position: absolute;
}
div.hx-index-chart-bar-bg {
    background: #bbb;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 49.5%;
}

.chart-bar-axis {
    border-top: 1px solid black;
    position: relative;
    flex: 1;
    height: 20px;
}
.chart-bar-axis > div {
    position: absolute;
    top: 0;
    width: 1px;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chart-bar-axis-vertical-tick {
    width: 1px;
    background: black;
    height: 6px;
}
.chart-bar-axis-tick-number {
    white-space: nowrap;
    font-size: 13px;
}
.chart-bar-axis-container {
    display: flex;
    flex-direction: row;
}

.split-chart-info {
    padding: 9px 6px;
    font-size: 13px;
    line-height: 14px;
    font-weight: normal;
    top: calc(50% - 23px);
}

.split-chart-info span {
    display: block;
}