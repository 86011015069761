.responses_module_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    min-height: 800px;
}

.responses_module_container__header {
    width: 100%;
    display: flex;
    padding: 5px 20px 15px;
    height: 60px;
}

.responses-title {
    font-size: 22px;
    font-weight: 400;
    white-space: nowrap;
}