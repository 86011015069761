.top-words-action {
    padding: 5px;
    text-decoration: none;
    background: #e8e8e8;
    align-items: center;
    border: solid #ccc;
    border-width: 0 1px 0 0;
    cursor: pointer;
}

.top-words-action.active {
    color: #bf9f3f;
    background-color: #f6f6f6;
}

.top-words-action:hover {
    background-color: #f6f6f6;
}

.top-words-action:first-child {
    border-radius: 5px 0 0 5px;
}

.top-words-action:last-child {
    border-radius: 0 5px 5px 0;
    border-right-width: 0;
}

.top-words-action:first-child:last-child {
    border-radius: 5px;
}