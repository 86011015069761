.live-report-container {
    display: flex;
    flex: 1;
    align-self: stretch;
    height: calc(100vh - 50px);
    margin: -8px;
}

.live-report-left {
    flex: 1;
    padding: 10px;
}

.live-report-right {
    flex: 2;
    padding: 10px 10px 10px 0;
    height: 100%;
}

.live-report-feed {
    height: 100%;
    overflow-y: scroll;
}

.live-report-graphs {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.live-report-graphs-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    max-height: 50%;
}
.live-report-graphs-row:last-child {
    padding-bottom: 0;
}
.live-report-graphs-col {
    flex: 1;
    margin-right: 10px;
    max-height: 100%;
    min-width: 0;
    min-height: 0;
}
.live-report-graphs-col:last-child {
    margin-right: 0;
}

.live-report-graphs-col .chart {
    height: 100%;
    padding: 25px;
}

.live-report-graphs-col .chart .top-words-bar-chart .y-axis .tick text {
    font-size: 14px;
}

.live-report-feed-header {
    width: 100%;
    text-align: center;
}

.live-report-feed-response-enter {
    opacity: 0;
    transform: translate(-50%, 0)
}

.live-report-feed-response-enter-active {
    opacity: 1;
    transform: translate(0, 0);
    transition: transform 300ms ease-in;
}

.live-report-feed-response {
    position: relative;
    margin: 5px;
    border: 1px solid #e0e0d9;
    border-radius: 5px;
}

.live-report-feed-response .response_component {
    width: auto;
}

.live-report-feed-responses {
}

.live-report-container .filters-hoc-container {
    border-radius: 0;
    height: calc(100vh - 50px)
}

@media (min-width: 992px) {
    .live-report-container .filters-hoc-container.filters-hoc-container-hidden {
        left: -320px;
    }
}

.live-feed-toggle-filters {
    background: none;
    border: 0;
    border-radius: 4px;
    margin-left: 5px;
    cursor: pointer;
    padding: 5px;
}

.live-feed-toggle-filters:hover {
    background-color: #f4f4f4;
}

.live-feed-toggle-filters:active {
    background-color: #f4f4f4;
}
