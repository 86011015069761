.add_words {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.add_words__select_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 300;
    margin-bottom: 10px;
}
.add_words__select_container > span {
    margin-bottom: 10px;
}

.add_words__select_container > select {
    width: 90%;
    height: 30px;
    background-color: white;
    border-color: #e9ecef;
    margin-bottom: 10px;
    font-weight: 300;
}

.add_words__words_container {
    font-weight: 300;
    display: flex;
    flex-direction: column;
}

.add_words__words_container__word {
    margin-bottom: 10px;
}

.add_words__words_container__word__input {
    display: flex;
    align-items: baseline;
}

.add_words__words_container__word__input > input {
    width: 90%;
    margin-right: 15px;
    border:1px solid #e9ecef;
    height: 30px;
    padding: 10px;
    font-weight: 300;
}

.add_words__textarea {
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    min-height: 100px;
    resize: vertical;
    height: 100px;
    border: 1px solid #e9ecef;
}
.add_ignore_words_input textarea{
    resize: vertical;
    min-height: 100px;
}