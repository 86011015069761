body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.8);
}

body {
  overscroll-behavior-x: none;
}

text {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
}

p {
  margin: 0;
}

h2 {
  margin: 0;
  font-weight: 300;
}

h1 {
  margin: 0;
  font-weight: 400;
}

line {
  shape-rendering: crispEdges;
}


pre {
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12)
}


.border_top {
  border-top: 1px solid #e9ecef;
}

.border_bottom {
  border-bottom: 1px solid #e9ecef;
}

.border_bottom_fat {
  border-bottom: 2px solid #e9ecef;
}

.border_bottom_super_fat {
  border-bottom: 5px solid #e9ecef;
}

.border_left {
  border-left: 1px solid #e9ecef;
}

.border_right {
  border-right: 1px solid #e9ecef;
}

.border_right_fat {
  border-right: 2px solid #e9ecef;
}

.odd {
  background-color: white;
}

.even {
  background-color: rgb(242, 242, 242);
}

/* Example Styles for React Tags*/
div.ReactTags__tags {
  position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 200px;
  border-radius: 2px;
  display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  border: 1px solid #eee;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}
div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: .05em .01em .5em rgba(0,0,0,.2);
  background: white;
  width: 200px;
}
div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.rounded-container {
  margin: 10px 0;
  background: white;
  border-radius: 8px;
  padding: 20px;
}