.form-group {
    padding: 5px 0;
}

.form-group label {
    display: block;
    padding-bottom: 5px;
}

.form-group input, .form-group select, .form-group textarea {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #e9ecef;
    background: white;
    border-radius: 8px;
    font-size: 14px;
}
.form-group input:disabled {
    border: 1px solid #f0f3f6;
    background: #f5f5f5;
    cursor: help;
}

.form-group.form-group-single-line label,
.form-group.form-group-single-line input,
.form-group.form-group-single-line select
{
    display: inline;
    width: auto;
    margin-right: 5px;
}

.form-group.form-group-horizontal input,
.form-group.form-group-horizontal select,
.form-group.form-group-horizontal textarea
{
    box-sizing: border-box;
    display: block;
    width: 100%;
}
.form-group-horizontal {
    display: flex;
    flex-direction: row;
}

.form-group-horizontal .form-group-horizontal-col-1,
.form-group-horizontal .form-group-horizontal-col-2 {
    text-align: left;
}
.form-group-horizontal label span {
    margin-top: 10px;
    display: block;
}
.form-group-horizontal .form-group-horizontal-col-1
{
    flex: 1;
}
.form-group-horizontal .form-group-horizontal-col-2
{
    flex: 3;
}

.form-group-input-group {
    display: flex;
}

.form-group-input-group input, .form-group-input-group select {
    flex: 1;
    margin-right: 10px;
    font-weight: inherit;
}
.form-group-input-group input:last-child, .form-group-input-group select:last-child {
    margin-right: 0;
}
