.reports-container {
    width: 100%;
    display: grid;
    overflow: visible;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.report-search {
    position: relative;
}

.report-search-simple {
    width: 100%;
    padding: 11px 100px 11px 10px;
    font-size: 18px;
    font-weight: 300;
    border: 1px solid #e9ecef;
}
.report-search-simple-active .report-search-simple {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
    border-bottom: 1px solid white;
}
.report-search-loading {
    padding: 0 10px;
}
.search-simple-controls {
    position: absolute;
    right: 4px;
    top: 3px;
}

.search-simple-button {
    border: 0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    background: transparent;
}
.search-simple-button:hover {
    background: #dddee136;
}
.search-advanced-button {
    border: 0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    color: white;
    background: #f26522;
    text-transform: uppercase;
}
.report-search-simple-go {
    color: #f26522;
}
.report-search-advanced-go-container {
    justify-content: flex-end;
}
.report-search-advanced {
    position: absolute;
    width: 100%;
    top: 100%;
    z-index: 2;
    margin-top: 2px;
}

.report-search-selected-fields-display {
    position: absolute;
    height: 100%;
    width: calc(100% - 120px);
    padding: 1px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.report-search-selected-fields-display > span {
    background: white;

    flex: 1;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.report-search-advanced-toggle {
    position: relative;
}
.search-advanced-selected {
    display: block;
    width: 8px;
    height: 8px;
    border: 0;
    border-radius: 4px;
    background: orange;
    position: absolute;
    right: 5px;
}

.reports-title > * {
    display: flex;
}

.report-div-like-button {
    padding: 10px;
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 400;
    width: auto;
    cursor: pointer;
}
.report-div-like-button:hover {
    background-color: #dddee136;
}
.reports-title .dropdown-menu {
    font-size: 16px;
    font-weight: 300;
}
.reports-title .report-div-like-button {
    margin-right: -5px;
}
.reports-search-calendar-container {
    position: relative;
}
.reports-search-calendar {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 2;
}
.reports-search-calendar .react-calendar {
    font-size: 12px;
    width: 280px;
}
input.reports-search-date-input:disabled {
    cursor: default;
}
.reports-search-domain-label {
    margin-right: 10px;
}
.form-group-help-icon {
    font-size: 12px;
    border: 1px dotted gray;
    padding: 0 4px;
    font-style: normal;
    border-radius: 50%;
}
.reports-round-shadow {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12);
    background: white;
    border-radius: 8px;
}

.reports-item {
    position: relative;
    padding-bottom: 70px;
}

.reports-item-info {
    font-size: 12px;
    display: block;
    margin: 5px 0 0 10px;
}

.reports-item-remove {

}

.reports-progressbar {
    width: 100%;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    height: 40px;
    overflow: hidden;
}

.reports-progressbar-percent {
    height: 40px;
    background: #039be5;
    transition: width 0.5s linear;
    position: relative;
}

.reports-progressbar-percent:after {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: linear-gradient(
            -45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    animation: progress-bar-stripes 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 50px 50px;
    }
}

.report-card-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

