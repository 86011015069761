.split-charts-header {
    text-align: center;
    margin: 0 0 10px 0;
}

.split-charts-controls {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.split-charts-controls > select {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    background: white;
    border: 1px solid #e9ecef;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 300;
    overflow: hidden;
    min-width: 0;
    width: 100%;
}
.split-charts-controls > select:last-child {
    margin-right: 0;
}

.split-charts-controls > .radio {
    width: auto;
    flex-wrap: nowrap;
    flex-shrink: 0;
}

.split-charts .report_info {
    margin-bottom: 10px;
    position: relative;
}

.split-chart-loading {
    position: absolute;
    top: 15px;
    left: 10px;
}
